import { Course } from "@/interfaces/api";
import { PersonCourse } from "@/interfaces/PersonCourse";

class TermHelper {
  /**
   * Gets a distinct list of terms from the specified courses sorted in chronological order
   * Eg: ["VT22", "HT22", "VT23"]
   *
   * @param {Course[]} courses The courses
   * @returns {string[]} The terms
   */
  getTerms(courses: Course[]): string[] {
    const terms = (courses as PersonCourse[]).map((course) => {
      return course.term;
    });

    const distinctTerms = Array.from(new Set(terms));

    return distinctTerms.sort(this.sortByTermAndYear());
  }

  /**
   * Gets courses within the specified term
   *
   * @param {Course[]} courses The courses
   * @param {string} term The term
   * @returns {PersonCourse[]} The courses within the specified term
   */
  getCoursesByTerm(courses: Course[], term: string): PersonCourse[] {
    return (courses as PersonCourse[]).filter((course) => course.term === term);
  }

  /**
   * Gets a translated longer version of the specified term
   * eg: HT23 => HT/Autumn 2023
   *
   * @param {string} term The term
   * @returns {string} The translated longer version
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  getTermString(term: string, t: Function): string {
    const currentYear = new Date().getFullYear().toString();
    const year = `${currentYear.substring(0, 2)}${term.substring(2)}`;
    const semesterSv = term.substring(0, 2);
    const semesterEn =
      semesterSv === "HT"
        ? t("note-taking-request.autumn")
        : t("note-taking-request.spring");

    const termString = `${semesterSv}/${semesterEn} ${year}`;
    return termString;
  }

  /**
   * Sorts the terms first by year and then by term
   */
  private sortByTermAndYear(): ((a: string, b: string) => number) | undefined {
    return (a, b) => {
      const a1 = parseInt(a.substring(2));
      const b1 = parseInt(b.substring(2));
      const a2 = a.substring(0, 2);
      const b2 = b.substring(0, 2);

      if (a1 < b1) {
        return -1;
      }
      if (a1 > b1) {
        return 1;
      }
      if (a2 > b2) {
        return -1;
      }
      if (a2 < b2) {
        return 1;
      }
      return 0;
    };
  }
}

export default new TermHelper();
