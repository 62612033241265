
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import Tooltip from "@/components/Tooltip.vue";
import TextInput from "@/components/Form/TextInput.vue";
import CheckboxInput from "@/components/Form/CheckboxInput.vue";
import DateHelper from "@/helpers/date-helper";
import { PersonCourse } from "@/interfaces/PersonCourse";

@Options({
  components: {
    TextInput,
    CheckboxInput,
    Tooltip,
  },
  data: () => ({
    /**
     * Used to disable the checkbox
     */
    isDisabled: false,
  }),
  props: {
    /**
     * The PersonCourse
     */
    course: {
      type: Object as PropType<PersonCourse>,
      required: true,
    },
    tooltipText: {
      type: String as PropType<string>,
      required: false,
    },
    courseOccurrenceId: {
      type: Number as PropType<number>,
      required: true,
    },
    isHighlighted: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isSupportCourse: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isCompleted: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isDeletedByAdmin: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  emits: ["courseCheckedChanged"],
  computed: {
    /**
     * Returns the course name
     */
    courseName(): string {
      return this.course
        ? this.$i18n.locale === "sv"
          ? this.course?.titleSv
          : this.course?.titleEn
        : "";
    },
    /**
     * Sets whether the checkbox is checked
     */
    isChecked(): boolean {
      if (this.isDeletedByAdmin) {
        this.course.checked == false;
        return false;
      }
      return this.course.checked;
    },
  },
  methods: {
    /**
     * Returns the updated course to the parent component
     */
    onCourseCheckedChange(isChecked: boolean): void {
      this.course.checked = isChecked;
      this.$emit("courseCheckedChanged", this.course);
    },
    /**
     * Reroutes to the share notes view
     */
    onShareButtonClicked(): void {
      const lucatid = this.$route.query.lucatid;

      let route = {
        name: "ShareNotes",
        params: { id: this.courseOccurrenceId },
        query: {},
      };

      if (lucatid) {
        route.query = { lucatid: lucatid };
      }

      this.$router.push(route);
    },
    /**
     * Sets whether the checkbox is disabled
     */
    setIsDisabled(): void {
      this.isDisabled =
        this.course.disabled || this.isDeletedByAdmin || this.isCompleted;
    },
    /**
     * Used to hide share notes button if course ended over 2 weeks ago
     */
    courseIsRecentlyEnded(): boolean {
      const daysSinceCourseEnded = DateHelper.daysBetweenDates(
        this.course.courseEndDate,
        new Date()
      );
      return daysSinceCourseEnded <= 14;
    },
    formatDate(date: string): string {
      return DateHelper.formatDate(date);
    },
  },
  mounted() {
    this.setIsDisabled();
  },
})
export default class CourseInput extends Vue {}
